import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'wellness',
  formId: 'DK_CATEGORY_WELLNESS_WIDE',
  title: 'Wellness',
  subTitle: 'The greatest wealth is health... and we all know it!',
  icon: '/icons/wellness.svg',
  image: '/assets/images/sunrise/wellness_supermercat.png',
  theme: 'sunrise-wellness',
  sections: [
    {
      sectionId: 'hero',
      label: 'Wellness',
      title: 'The greatest wealth is health... and we all know it!',
      description:
        "Mindfulness and balance are all the rage these days. From yoga to pilates there's bound to be something worth investing in for the benefit of your employees.",
      image: '/assets/images/sunrise/wellness_supermercat.png',
      icon: '/icons/wellnewss.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Good things come to those who sweat... or not.',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/wellness/service_1.svg',
          title: 'Meditation',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_2.svg',
          title: 'Classes',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_3.svg',
          title: 'Workshops',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_4.svg',
          title: 'Wellness talks',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Wellbeing is only a few steps away',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/wellness/journey_1.svg',
          title: 'Tell us what you need',
          description: 'Let us understand what your physical and mental needs are.',
        },
        {
          icon: '/assets/images/services/wellness/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal to free up headspace.',
        },
        {
          icon: '/assets/images/services/wellness/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! You are now taking great care of your colleagues.',
        },
        {
          icon: '/assets/images/services/wellness/journey_4.svg',
          title: 'Get physically and mentally fit',
          description: 'Now it’s time to balance energy and reboot.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love some wellness from Good Monday!',
      description: '',
      image: '/assets/images/services/wellness/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-people-outline',
          title: 'Best partners out there!',
          description: 'We’ve gathered all the best practitioners.',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'Discover a ton of new ways to get your colleagues healthy and reloaded.',
        },
        {
          icon: 'eva eva-video-outline',
          title: 'Online and in person',
          description: 'In your office or online. Up to you. We have it all.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that 3 out of 4 employees would like their workplace to provide wellness at work?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
